<template>
  <div class="about-us">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content box" id="content">
      <span class="back"
        @click="goBack">返回</span>
      <h1>{{newsinfo.title}}</h1>
      <p>{{newsinfo.create_time}}</p>
      <div class="news-content"
        v-html="newsinfo.content"></div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'

export default {
  name: 'aboutus',
  data () {
    return {
      current: 'news',
      newsinfo: {}
    }
  },
  methods: {
    goBack: function () {
      this.$router.go(-1)
    }
  },
  mounted: function () {
    var div = document.getElementById('content')
    var h = document.documentElement.clientHeight || document.body.clientHeight
    div.style.minHeight = h - 160 + 'px'
  },
  components: {
    HeaderNav,
    FooterNav
  },
  created () {
    // 获取用户信息
    this.$post('index/articleDetail', {id: this.$route.query.aid}).then(res => {
      if (res.code === 1) {
        this.newsinfo = res.data
      } else {
        this.$layer.msg(res.msg)
      }
    }).catch(response => {
      this.$layer.msg('网络异常，请稍后重试！')
    })
  }
}
</script>

<style lang="less" scoped>
.content{
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 36px;
  padding: 60px 200px 60px 200px;
  position: relative;
}
.back{
  color: #999;
  font-size: 14px;
  line-height: 30px;
  padding-left: 26px;
  background: url(../assets/icon/icon_back_arrow.png) no-repeat left center;
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 1;
  cursor: pointer;
}
.content h1{
  color: #666;
  font-size: 30px;
  line-height: 2;
}
.content p{
  color: #999;
  font-size: 14px;
  line-height: 2;
}
.content .news-content{
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 30px;
}
.news-content img{
  max-width: 100%;
}
</style>
